import React from 'react';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function Loader() {
  return (<div>
    <Grid container="container" direction="row" justify="center" alignItems="center" style={{
        minHeight: '100vh',
        width: '100vw'
      }}>
      <CircularProgress color='primary'/>
    </Grid>
  </div>)
}

// <SEO title='Loading'/>
