/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
// import {useStaticQuery, graphql} from "gatsby"

// import Header from "./header"
import "./layout.css"

import GoogleFontLoader from 'react-google-font-loader';

let fontLoader

if (typeof window !== `undefined`) {
  fontLoader = <GoogleFontLoader fonts={[{
        font: 'Work Sans',
        weights: [400, 800]
      }
    ]}/>
}

const Layout = ({children}) => {
  // const data = useStaticQuery(graphql `
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)

  return (<> < div
  // style={{
  //   margin: `0 auto`,
  //   maxWidth: 960,
  //   padding: `0 1.0875rem 1.45rem`,
  // }}
  > {
    fontLoader
  } < main > {
    children
  }</main> < /div>
    </ >)
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
