import firebase from '@firebase/app';
import '@firebase/auth';
import '@firebase/firestore';
import '@firebase/functions';
import '@firebase/analytics';

const config = {
  apiKey: "AIzaSyBDEnynL3WSHL0Jcjh6Zydf3HtMNOp8LPw",
  authDomain: "testing-1e2ed.firebaseapp.com",
  databaseURL: "https://testing-1e2ed.firebaseio.com",
  projectId: "testing-1e2ed",
  storageBucket: "testing-1e2ed.appspot.com",
  messagingSenderId: "1003370444025",
  appId: "1:1003370444025:web:6c8812fc85e2e5f6fa9e28",
  measurementId: "G-X545MCBCZE"
};

let instance;

export default function getFirebase() {
  if (typeof window !== 'undefined') {
    if (instance)
      return instance;
    instance = firebase.initializeApp(config);
    firebase.analytics();
    return instance;
  }

  return null;
}

// const displayName = activeUser.displayName;
// const email = activeUser.email;
// const emailVerified = activeUser.emailVerified;
// const photoURL = activeUser.photoURL;
// const isAnonymous = activeUser.isAnonymous;
// const uid = activeUser.uid;
// const providerData = activeUser.providerData;
